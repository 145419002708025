import { Link, LinkProps } from "@remix-run/react";
import { ButtonHTMLAttributes, ReactNode } from "react";

type CustomButtonLinkProps = LinkProps & {
  children: ReactNode;
  inverted?: boolean;
};

export function CustomButtonLink({
  children,
  to,
  className,
  inverted = false, // Default to false
  ...props
}: CustomButtonLinkProps) {
  return (
    <Link
      to={to}
      className={`${className} border border-white px-4 py-[0.62rem] transition duration-200 ease-in-out text-m-btn md:px-6 md:py-3 md:text-pm ${
        inverted
          ? "bg-black text-white hover:bg-white hover:text-black"
          : "bg-white text-black hover:bg-black hover:text-white"
      }`}
      prefetch="intent"
      {...props}
    >
      {children}
    </Link>
  );
}

type CustomButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: ReactNode;
  inverted?: boolean;
};

export function CustomButton({
  children,
  className,
  inverted = false, // Default to false
  ...props
}: CustomButtonProps) {
  return (
    <button
      className={`${className} border border-white px-4 py-[0.62rem] transition duration-200 ease-in-out text-m-btn md:px-6 md:py-3 md:text-pm ${
        inverted
          ? "bg-black text-white hover:bg-white hover:text-black"
          : "bg-white text-black hover:bg-black hover:text-white"
      }`}
      {...props}
    >
      {children}
    </button>
  );
}
